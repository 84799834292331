import React from "react";



const Loader = () => {
  return (
    <div id="preloader">
        <img src="https://hub.mileageriot.com/assets/images/logo_sm.png" width={150} alt="" /><br/>
        <div id="status">
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
        </div>
    </div>
  );
};

export default Loader;