import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { FaCalendarAlt, FaClock, FaMap, FaMapMarked, FaMapMarkedAlt, FaRegClock, FaRoad, FaServer } from 'react-icons/fa';
import Fancybox from '../components/Fancybox';

const ConvoyItem = () => {
  const { id } = useParams();
  const [convoyItem, setConvoyItem] = useState(null);
  const [upcomingConvoys, setUpcomingConvoys] = useState([]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat('ru', { year: 'numeric', month: '2-digit', day: '2-digit' });
    return formatter.format(date);
  };
  useEffect(() => {
    const convoyCacheKey = `convoyItem-${id}`;
    const convoyCacheTimestampKey = `convoyItemTimestamp-${id}`;
    const convoysCacheKey = `upcomingConvoys-${id}`;
    const convoysCacheTimestampKey = `upcomingConvoysTimestamp-${id}`;
  
    const isCacheValid = (cacheTimestampKey) => {
      const cachedTimestamp = localStorage.getItem(cacheTimestampKey);
      return cachedTimestamp && (new Date().getTime() - new Date(parseInt(cachedTimestamp)).getTime()) < 3 * 60 * 60 * 1000; // 3 hours in milliseconds
    };
  
    const fetchConvoyItem = async () => {
      try {
        if (localStorage.getItem(convoyCacheKey) && isCacheValid(convoyCacheTimestampKey)) {
          setConvoyItem(JSON.parse(localStorage.getItem(convoyCacheKey)));
        } else {
          const response = await fetch(`https://hub.mileageriot.com/api/${id}/event`);
          const data = await response.json();
          setConvoyItem(data);
          localStorage.setItem(convoyCacheKey, JSON.stringify(data));
          localStorage.setItem(convoyCacheTimestampKey, new Date().getTime().toString());
        }
      } catch (error) {
        console.error('Error fetching convoy item data:', error);
      }
    };
  
    const fetchUpcomingConvoys = async () => {
      try {
        if (localStorage.getItem(convoysCacheKey) && isCacheValid(convoysCacheTimestampKey)) {
          setUpcomingConvoys(JSON.parse(localStorage.getItem(convoysCacheKey)));
        } else {
          const response = await fetch(`https://hub.mileageriot.com/api/${id}/getUpcomingConvoys`);
          const data = await response.json();
          setUpcomingConvoys(data);
          localStorage.setItem(convoysCacheKey, JSON.stringify(data));
          localStorage.setItem(convoysCacheTimestampKey, new Date().getTime().toString());
        }
      } catch (error) {
        console.error('Error fetching upcoming convoys:', error);
      }
    };
  
    fetchConvoyItem();
    fetchUpcomingConvoys();
  }, [id]);
  

  return (
        <div className="container mt-4">
            <div className="row">
                <div className="col-md-9">
                {convoyItem && (
                    <div className='card border-primary border-2 bg-transparent'>
                        <img src={ convoyItem.banner ?? 'https://hub.mileageriot.com/assets/images/banner/banner.jpg'} className='img-fluid' style={{ maxHeight: '25rem', width: '100%'}} alt="" />
                        <div className="p-3">
                            <h4 className='text-primary fw-bold'>{convoyItem.title}</h4>
                            <hr className='border-2 border-primary w-100'/>
                            <h6 className="text-white"><FaCalendarAlt className='text-primary' style={{ marginRight: '5px' }}/>Дата: <span className='text-primary'>{formatDate(convoyItem.date)}</span></h6>
                            <h6 className="text-white"><FaClock className='text-primary' style={{ marginRight: '5px' }}/>Время парковки: <span className='text-primary'>{convoyItem.meetup_time} UTC</span></h6>
                            <h6 className="text-white"><FaRegClock className='text-primary' style={{ marginRight: '5px' }}/>Время выезда: <span className='text-primary'>{convoyItem.departure_time} UTC</span></h6>
                            <h6 className="text-white"><FaMapMarkedAlt className='text-primary' style={{ marginRight: '5px' }}/>Место парковки: <span className='text-primary'>{convoyItem.start_location}</span></h6>
                            <h6 className="text-white"><FaMapMarked className='text-primary' style={{ marginRight: '5px' }}/>Место прибытия: <span className='text-primary'>{convoyItem.end_location}</span></h6>
                            <h6 className="text-white"><FaMap className='text-primary' style={{ marginRight: '5px' }}/>DLC: <span className='text-primary'>{convoyItem.dlc}</span></h6>
                            <h6 className="text-white"><FaServer className='text-primary' style={{ marginRight: '5px' }}/>Сервер: <span className='text-primary'>{convoyItem.server}</span></h6>
                            <h6 className="text-white"><FaRoad className='text-primary' style={{ marginRight: '5px' }}/>Дистанция: <span className='text-primary'>{convoyItem.distance} KM</span></h6>
                            <hr className='border-2 border-primary w-100'/>
                            <Fancybox
                                options={{
                                Carousel: {
                                    infinite: false,
                                },
                                }}
                            >
                            <a data-fancybox="gallery" href={convoyItem.route}>
                                <img src={convoyItem.route} className='img-fluid' alt="" />
                            </a>
                            </Fancybox>
                        </div>
                    </div> 
                )}
                </div>
                <div className="col-md-3">
                {upcomingConvoys.map(convoy => (
                    <div key={convoy.id} className='card border-primary border-2 bg-transparent mb-4'>
                    <Link to={`/events/${convoy.id}`}>
                        <img class="img-fluid" src={convoy.banner ?? 'https://cdn.discordapp.com/attachments/1012428421520965642/1086688659975196752/image_5.png'} style={{ height: '7rem', width: '100%'}} alt="" />
                        <div className="p-2">
                            <h5 className='text-white'>{convoy.title} - <span className='text-primary'>{formatDate(convoy.date)}</span></h5>
                        </div>
                    </Link>
                    </div>
                ))}
                </div>
            </div>
        </div>
        
  );
};

export default ConvoyItem;
