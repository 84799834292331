import React, { useState, useEffect } from 'react';


import { Link } from 'react-router-dom';

const News = () => {
  const [newsData, setNewsNews] = useState([]);



  useEffect(() => {
    const fetchNews = async () => {
      try {
        const cachedData = localStorage.getItem('newsData');
        const cachedTimestamp = localStorage.getItem('newsDataTimestamp');
  
        const isCacheValid = cachedTimestamp && (new Date().getTime() - new Date(parseInt(cachedTimestamp)).getTime()) < 3 * 3600 * 1000; // 3 hours in milliseconds
  
        if (cachedData && isCacheValid) {
          setNewsNews(JSON.parse(cachedData));
        } else {
          const response = await fetch('https://hub.mileageriot.com/api/news');
          const data = await response.json();
          const sortedNews = data.data;
          setNewsNews(sortedNews);
          localStorage.setItem('newsData', JSON.stringify(sortedNews));
          localStorage.setItem('newsDataTimestamp', new Date().getTime().toString());
        }
      } catch (error) {
        console.error('Error fetching news data:', error);
      }
    };
  
    fetchNews();
  }, []);
  
  

  return (
    <section className="news">
        <div className="container">
            <h2 className="featurette-heading  text-center text-white">Новости</h2>
            <hr className="border-2 mx-auto border-primary w-25" />
            <div className='row mt-5'>
                {Array.isArray(newsData) ? (
                    <>
                    {newsData.map((newsItem, index) => (
                        <div key={index} className="col-md-4 mb-4 col-12">
                            <div className="card border-primary bg-transparent">
                                <Link to={`/news/${newsItem.slug}`}>
                                    <div className="image-container">
                                        <img className="img-fluid rounded image" src={newsItem.image ?? ''} alt="" />
                                        <div className="overlay">
                                            <h5 className="text-white">{newsItem.title}</h5>
                                            <hr className='border-2 border-primary w-50'/>
                                            <p className='text-white' style={{ minHeight: '8rem'}}>
                                                {newsItem.description}
                                            </p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    
                    ))}
                    </>
                ) : (
                    <h3 className='text-white text-center'>Что то пошло не так...</h3>
                )}
            </div>
        </div>
    </section>
  );
};

export default News;
