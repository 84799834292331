import React, { Component } from 'react'

export default class Privacy extends Component {
  render() {
    return (
        
    <section class="privacy text-white">
        <div class="container">
          <h3>Executive Summary</h3>
        <hr class="border-primary border-1 w-50" />
        <p>
          Mileage Riot has established a Privacy Policy for their service operated via
        <a href="https://mileageriot.com" class="text-primary"> www.mileageriot.com</a>. The policy outlines the collection, use, and disclosure of personal
        information. This report evaluates the existing policy and provides recommendations for
        enhancement.
        </p>
        <h3>
          Current Privacy Policy Evaluation
        </h3>
        <hr class="border-primary border-1 w-50" />
        <b>Compliance and Clarity</b>
        <ul>
          <li>The policy adheres to standard privacy principles, offering clear directives on user data handling.</li>
          <li>Terminology aligns with the Terms and Conditions, ensuring consistency across documents.</li>
        </ul>
        <b>Information Collection and Use</b>
        <ul>
          <li>Personal data collection is stated, but lacks specifics on data retention and exact usage,which may be required under certain jurisdictions.</li>
        </ul>
          
        <b>Log Data and Cookies</b>
        <ul>
          <li>The policy correctly informs users of log data collection and cookie usage.</li>
          <li>It would benefit from more detail on the types of cookies used and their specific purposes.</li>
        </ul>
        <b>Service Providers</b>
        <ul>
          <li>Disclosure about third-party access to personal information is adequate, but it lacks
            detail about the vetting process for these providers, which could strengthen user trust.
            Security
          </li>
          <li>
            The policy addresses efforts to protect user information; however, it does not detail the
            security measures in place, which could be informative for users and establish
            transparency.
          </li>
        </ul>
        <b>Links to Other Sites</b>
        <ul>
          <li>
            The external links disclaimer is appropriate, but the policy could offer advice on safe
            browsing practices when leaving the company’s website.
            Children’s Privacy
          </li>
          <li>
              Addresses compliance with laws regarding children's privacy, but should specify the
            steps taken when unauthorized information is collected.
            GDPR Data Protection Rights
          </li>
          <li>
              The policy enumerates the rights provided by the GDPR but fails to mention the legal
            basis for processing or the data controller’s contact details, which are required under
            GDPR.
          </li>
          
        </ul>
        <h3>Recommendations for Policy Enhancement</h3>
        <hr class="border-primary border-1 w-50" />
        <ol>
          <li>
                Data Retention: Include specifics on how long user data is retained and the criteria for
            data deletion.
          </li>
          <li>
              Cookie Detailing: Provide a detailed cookie policy, enumerating types, purposes, and
            consent mechanisms.
          </li>
          <li>
              Security Protocols: Elaborate on the security measures and technologies employed to
            safeguard personal information.
          </li>
          <li>
              Third-Party Vetting: Describe the due diligence process for selecting and managing
            third-party service providers.
          </li>
          <li>
              GDPR Compliance: Integrate detailed GDPR-required information, including processing
            grounds and data controller details.
          </li>
          <li>
              Regular Audits: Implement a schedule for regular privacy policy reviews and audits to
            ensure ongoing compliance with evolving data protection laws.
          </li>
          <li>
              User-Friendly Language: Translate the policy into user-friendly language, minimizing
            legal jargon to enhance comprehensibility.
          </li>
          <li>
              Contact Transparency: Provide more than just an email for contact—include a physical
            address or phone number for more substantial inquiries.
          </li>
        </ol>
        <h3>Conclusion</h3>
        <hr class="border-primary border-1 w-50" />
        <p>
          The final revised text should incorporate the recommended changes, ensuring it meets current
        data protection standards and enhances user understanding and trust. The final text should be
        made accessible, both in terms of language and availability, on the Mileage Riot website.
        Note: This report is provided on courtesy, therefore not being a legal advice rendered by the
        legal professional.
        </p>
        <h3>Policy Text Proposal</h3>
        <hr class="border-primary border-1 w-50" />
        <b>Introduction</b>
        <p>
          Mileage Riot values your privacy. This GDPR Privacy Policy outlines our practices regarding the
        safeguarding and handling of your personal data in adherence to the General Data Protection
        Regulation (GDPR).
        </p>
        <b>Personal Data We Collect</b>
        To enhance our services for all users, we collect the following data:
        <ul>
          <li>Identifiers: Names, addresses, and contact information.</li>
          <li>Usage Data: Browsing history, preferences, and service interactions.</li>
          <li>Log Data: IP addresses, browser types, and visited pages.</li>
        </ul>
        <b>How We Use Your Data</b>
        We use collected data to:
        <ul>
          <li>Enhance, maintain, and deliver our services.</li>
          <li>Communicate with you about service usage.</li>
          <li>Ensure our services' security.</li>
          <li>Fulfill legal requirements.</li>
        </ul>
        <b>Data Retention</b>
        <p>Personal data is kept as long as necessary to provide requested services and for legitimate legal or business reasons.</p>
        <b>Cookies and Tracking Technologies</b>
        <p>Cookies are utilized to personalize and augment your site experience. You may accept or decline cookies.</p>
        <b>Sharing Your Data</b>
        Personal data sharing is limited to:
        <ul>
          <li>Compliance with EU and Russian Federation laws.</li>
          <li>Service provision and improvement.</li>
          <li>Obtaining your consent.</li>
        </ul>
        <b>In-Game Tracking</b>
        <p>
          By installing the Truckershub In-Game Tracker, you acknowledge and consent to the data
        collection and usage practices as defined in Truckershub's policy. It is your responsibility to
        review these practices, which are crafted to comply with GDPR and other applicable
        regulations, ensuring your data is handled with care. If you have any questions or concerns
        about Truckershub's data practices, please contact TruckersHub directly.
        </p>
        <b>Security</b>
        <p>We employ security measures like encryption and access control to protect your data.</p>
        <b>Your Data Protection Rights Under GDPR</b>
        Under GDPR, you have the right to:
        <ul>
          <li>Access: Obtain personal data copies.</li>
          <li>Rectification: Correct inaccurate data.</li>
          <li>Erasure: Delete data under certain conditions.</li>
          <li>Restrict Processing: Limit data processing.</li>
          <li>Object to Processing: Challenge data processing.</li>
          <li>Data Portability: Transfer data to another entity or to yourself.</li>
        </ul>
        <b>Exercising Your GDPR Data Protection Rights</b>
        For rights exercise, contact us at:
        <ul>
          <li>Email: <a href="mailto: admin@mileageriot.com" class="text-primary">admin@mileageriot.com</a></li>
        </ul>
        We aim to respond within one month.
        <b>Children’s Privacy</b>
        <p>Our services do not target those under 16. We do not intentionally gather children's data.</p>
        <b>Changes to This Policy</b>
        <p>We may modify this policy. Updates will be posted here.</p>
        <b>Contact Us</b>
        <p>For questions or suggestions regarding our GDPR Privacy Policy, reach out at <a href="mailto: admin@mileageriot.com" class="text-primary text-decoration-none">admin@mileageriot.com</a></p>
        <b>This policy is effective as of 01.01.2023</b>
        </div>
    </section>
        
    )
  }
}
