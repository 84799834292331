import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';


const NewsItem = () => {
  const { slug } = useParams();
  const [newsItem, setNewItem] = useState(null);
  

  useEffect(() => {
    const fetchNewsItem = async () => {
      const cacheKey = `newsItem-${slug}`;
      const cacheTimestampKey = `newsItemTimestamp-${slug}`;
  
      try {
        const cachedData = localStorage.getItem(cacheKey);
        const cachedTimestamp = localStorage.getItem(cacheTimestampKey);
  
        const isCacheValid = cachedTimestamp && (new Date().getTime() - new Date(parseInt(cachedTimestamp)).getTime()) < 12 * 3600 * 1000; // 3 hours in milliseconds
  
        if (cachedData && isCacheValid) {
          setNewItem(JSON.parse(cachedData));
        } else {
          const response = await fetch(`https://hub.mileageriot.com/api/news/${slug}`);
          const data = await response.json();
          setNewItem(data.data);
          localStorage.setItem(cacheKey, JSON.stringify(data.data));
          localStorage.setItem(cacheTimestampKey, new Date().getTime().toString());
        }
      } catch (error) {
        console.error('Error fetching news item data:', error);
      }
    };
  
    fetchNewsItem();
  }, [slug]);
  

  return (
    
    <section className="news_item">
        <div className="container  animate__animated animate__slow animate__fadeInUp">
          {newsItem && (
            <div>
              <h2 className="featurette-heading  text-center text-white">{newsItem.title}</h2>
              <hr className="border-2 mx-auto border-primary w-25" />
              <div className='row mt-5'>
                <div className="col-md-12">
                  <div className="card bg-transparent border-primary">
                    <div className="card-img">
                      <img class="img-fluid" src={newsItem.image ?? ''}  alt="" />
                    </div>
                    <div className="p-3">
                      <div className='text-white' dangerouslySetInnerHTML={{ __html: newsItem.content }} />
                    </div>
                  </div>
                </div>
                    
              </div>
            </div>
          )}
        </div>
    </section>
  );
};

export default NewsItem;
