import React, { useState } from 'react';
import Fancybox from './Fancybox';
import { FaPlayCircle } from 'react-icons/fa';



const About = () => {
  const [showMore, setShowMore] = useState(false);

  // Function to handle the 'Read More' action
  const readMore = () => {
    setShowMore(true);
  };
  return (
    <section className="about">
      <div className="container">
        <div className="row featurette">
          <div className="col-md-5">
              <div className="play-card">
                  <div className="image-container">
                      <Fancybox
                        options={{
                          Carousel: {
                            infinite: false,
                          },
                        }}
                      >
                      <img src="https://old.mileageriot.com/assets/front/img/uploads/16685453741803122197.webp" className="img-fluid" alt="" />
                      <a data-fancybox="gallery" href="https://www.youtube.com/watch?v=F8sH7eC24PM&ab_channel=MileageRiot" className="section_promo">
                        <button className="btn btn-primary play-button">
                          <FaPlayCircle/>  
                        </button>
                      </a>
                      </Fancybox>
                  </div>
              </div>
          </div>
          <div className="col-md-7">
            <h2 className="featurette-heading text-white">О нас</h2>
            <hr className="border-2 border-primary w-25" />
            <p className="lead text-white">
              Mileage Riot - это верифицированная и полностью русскоязычная виртуальная транспортная компания, наполненная большими идеями и успешной реализацией. Мы работаем с 18 августа 2021 года, и за это время мы успешно провели множество конвоев с тщательной организацией.

              Наша активная компания насчитывает более 200 сотрудников из разных стран. <br />

              {!showMore && (
                  <button className="btn btn-primary mt-4"  onClick={readMore}>Читать больше</button>
              )}
              {showMore && (
                <span id="about_continue" className="mt-1  animate__animated animate__slow animate__fadeInDown">
                  У нас в компании вы найдете мощную технологическую систему, готовую взаимодействовать с вами и способствовать вашему развитию. Мы гордимся красивым дизайном нашего стиля. Наш коллектив включает в себя выдающихся специалистов, администрацию с богатым опытом и отзывчивым отношением к каждому водителю. 
                  Присоединившись к нашей компании, вы получите богатый опыт и насладитесь множеством эмоций от участия в крупных мероприятиях! Даже если у вас нет опыта в игре, не переживайте! Мы с удовольствием поможем вам освоить все необходимые навыки и достичь заслуженной роли "Опытного водителя". Присоединяйтесь к Mileage Riot и давайте вместе создадим захватывающее и успешное будущее в мире виртуальных грузоперевозок!
                </span>
              )}
              
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}; 

export default About;
