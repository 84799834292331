import React, { Component } from 'react'

import Header from '../components/Header';
import About from '../components/About';
import Stats from '../components/Stats';
import Partners from '../components/Partners';
import Apply from '../components/Apply';

export default class Home extends Component {
  render() {
    return (
        <div className=''>
        
        <Header />
        <About />
        <Stats />
        <Partners />
        <Apply />
        
        <div className='h-[10vh]'></div>
      </div>
    )
  }
}
