import React from 'react';
import Scania from '../assets/img/scania.svg';
import { Link } from 'react-router-dom';

import { FaDiscord, FaInstagram, FaSteam, FaTruck, FaTwitch, FaVk, FaYoutube } from 'react-icons/fa';

const Header = () => {
  return (
    
    <section className="home">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <h1 className="text-primary display-5 fw-bold lh-1 mb-3"><span className="text-white">Mileage</span> Riot</h1>
            <hr className="border-2 border-primary w-25" />
            <p className="lead text-white fs-4">
    					    Добро пожаловать в компанию <span className="text-primary">Mileage Riot!</span><br/>
    					    Заинтересованы? Присоединяйтесь к нам!
            </p>
            <div className="row">
              <div className="col-12">
                <Link to="https://apply.mileageriot.com" className="btn btn-apply me-3 animate__slower animate__animated animate__pulse animate__infinite">
                  <FaSteam className='me-1'/>
                  Вступить
                </Link>
                <Link to="https://discord.mileageriot.com" className="btn btn-discord">
                  <FaDiscord className='me-1'/>
                  Discord
                </Link>
                
                  <div className="row mt-4">
                    <div className="col-md-12">
                        <Link to="https://truckersmp.com/vtc/48336-mileageriot" target="_blank" title="TruckersMP" className="btn btn-icon btn-icon-tmp">
                            <FaTruck/>
                        </Link>
                        <Link to="https://vk.com/mileageriot" target="_blank" title="Vk" className="btn btn-icon btn-icon-vk">
                            <FaVk/>
                        </Link>
                        <Link to="https://www.youtube.com/@mileageriot_official" target="_blank" title="YouTube" className="btn btn-icon btn-icon-youtube">
                            <FaYoutube/>
                        </Link>
                        <Link to="https://twitch.tv/mileageriot_official" target="_blank" title="Twitch" className="btn btn-icon btn-icon-twitch">
                            <FaTwitch/>
                        </Link>
                        <Link to="https://www.instagram.com/mileageriot_official/" target="_blank" title="Instagram" className="btn btn-icon btn-icon-instagram">
                            <FaInstagram/>
                        </Link>
                    </div>
                  </div>
              </div> 
            </div>
          </div>
          <div className="col-12 col-sm-12 col-lg-6">
            <img src={ Scania } className="d-block mx-lg-auto img-fluid animate__animated animate__slow animate__fadeInRight" alt="" width="700" height="500" loading="lazy" />
          </div>
        </div>
      </div>
    </section>
    
  );
}; 

export default Header;
