import React, { useState, useEffect } from 'react';

import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { FaBox, FaTruck, FaUsers } from 'react-icons/fa';

const Stats = () => {
    const [stats, setStats] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await fetch(`https://hub.mileageriot.com/api/basic-stats`);
        const data = await response.json();
        setStats(data.data);
      } catch (error) {
        console.error('Error fetching stats item data:', error);
      }
    };

    fetchStats();
  }, []);
  const [animationStarted, setAnimationStarted] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.1,
  });
  return (
    
    <section className="stats">
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="card card-stats border-primary bg-transparent text-white text-center">
                <div className="card-body">
                    <div className="btn-icon-stats"><FaTruck/></div>
                    <div className="number-stats" ref={ref}>
                    {stats && stats.distance ? (
                        <>
                        {animationStarted || inView ? (
                            <CountUp
                            start={0}
                            end={stats.distance}
                            duration={2}
                            decimals={0}
                            separator=","
                            onEnd={() => setAnimationStarted(true)}
                            />
                        ) : (
                            0
                        )}
                        KM
                        </>
                    ) : (
                        'Loading...'
                    )}
                    </div>
                    <div className="text-stats">
                      Пройдено <br />
                      Расстояние
                    </div>
                </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card card-stats border-primary bg-transparent text-white text-center">
                <div className="card-body">
                    <div className="btn-icon-stats"><FaBox/></div>
                    <div className="number-stats" ref={ref}>
                    {stats && stats.distance ? (
                        <>
                        {animationStarted || inView ? (
                            <CountUp
                            start={0}
                            end={stats.jobs}
                            duration={2}
                            decimals={0}
                            separator=","
                            onEnd={() => setAnimationStarted(true)}
                            />
                        ) : (
                            0
                        )}
                        
                        </>
                    ) : (
                        'Loading...'
                    )}
                    </div>
                    <div className="text-stats">
                      Завершённых <br />
                      доставок
                    </div>
                </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card card-stats border-primary bg-transparent text-white text-center">
                <div className="card-body">
                    <div className="btn-icon-stats"><FaUsers/></div>
                    <div className="number-stats" ref={ref}>
                    {stats && stats.distance ? (
                        <>  
                        {animationStarted || inView ? (
                            <CountUp
                            start={0}
                            end={stats.users}
                            duration={2}
                            decimals={0}
                            separator=","
                            onEnd={() => setAnimationStarted(true)}
                            />
                        ) : (
                            0
                        )}
                        
                        </>
                    ) : (
                        'Loading...'
                    )}
                    </div>
                    <div className="text-stats">
                      Участники <br />
                      Компании
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
  );
}; 

export default Stats;
