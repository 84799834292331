import React, { useState, useEffect } from 'react';


const Members = () => {

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const cacheKey = 'rolesData';
    const cacheTimestampKey = 'rolesDataTimestamp';
  
    const fetchRoles = async () => {
      try {
        const cachedData = localStorage.getItem(cacheKey);
        const cachedTimestamp = localStorage.getItem(cacheTimestampKey);
  
        const isCacheValid = cachedTimestamp && (new Date().getTime() - new Date(parseInt(cachedTimestamp)).getTime()) < 1 * 3600 * 1000; // 3 hours in milliseconds
  
        if (cachedData && isCacheValid) {
          setRoles(JSON.parse(cachedData));
        } else {
          const response = await fetch('https://hub.mileageriot.com/api/roles');
          const data = await response.json();
          const rolesWithUsers = data.filter(role => role.users && role.users.length > 0);
          setRoles(rolesWithUsers);
          localStorage.setItem(cacheKey, JSON.stringify(rolesWithUsers));
          localStorage.setItem(cacheTimestampKey, new Date().getTime().toString());
        }
      } catch (error) {
        console.error('Error fetching roles data:', error);
      }
    };
  
    fetchRoles();
  }, []);
  

  return (
    <section className="members">
        <div className="container">
            <h2 className="featurette-heading text-center text-white">Знакомство с командой</h2>
            <hr className="border-2 mx-auto border-primary w-25" />
            <div className='row mt-5'>
                <div className="col-md-8 mx-auto">
                    {roles.map((role) => (
                        <div key={role.id} className="text-center mt-2">
                            {role.icon && (
                                <img src={`https://hub.mileageriot.com/storage/roles/${role.icon}`} width="45" alt="" />
                            )}
                            <div className="section-title">
                                <span className="text-uppercase text-white fw-bold">{role.name}</span>
                                <hr className="border-2 mx-auto border-primary w-25" />
                            </div>
                            <br />
                            <p className='text-white'>
                                {role.another_description}
                            </p>
                            <br />
                            <div className="row d-flex justify-content-center align-center">
                                {role.users.map(user => (
                                    <div key={user.id} className="col-lg-3">
                                        <img className="rounded-circle" style={{ width: '10rem', height: '10rem' }} src={user.avatar} alt="" />
                                        <h3 className='text-white'>{user.name}</h3>
                                    </div> 
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </section>
  );
};

export default Members;
