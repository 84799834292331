import React, { useState, useEffect } from 'react';

const Sponsors = () => {
  const [sponsors, setsponsors] = useState([]);

  useEffect(() => {
    const cacheKey = 'sponsorsData';
    const cacheTimestampKey = 'sponsorsDataTimestamp';

    const fetchsponsors = async () => {
      try {
        const cachedData = localStorage.getItem(cacheKey);
        const cachedTimestamp = localStorage.getItem(cacheTimestampKey);

        const isCacheValid =
          cachedTimestamp &&
          new Date().getTime() - new Date(parseInt(cachedTimestamp)).getTime() < 1  * 1000;

        if (cachedData && isCacheValid) {
          setsponsors(JSON.parse(cachedData));
        } else {
          const response = await fetch('https://hub.mileageriot.com/api/sponsors');
          const data = await response.json();
          const sponsorsWithUsers = categorizeUsers(data);
          setsponsors(sponsorsWithUsers);
          localStorage.setItem(cacheKey, JSON.stringify(sponsorsWithUsers));
          localStorage.setItem(cacheTimestampKey, new Date().getTime().toString());
        }
      } catch (error) {
        console.error('Error fetching sponsors data:', error);
      }
    };

    fetchsponsors();
  }, []);

  const categorizeUsers = (data) => {
    const goldVip = [];
    const silverVip = [];
    const bronzeVip = [];

    data.forEach((user) => {
      if (user.premium === 1) {
        goldVip.push(user);
      } else if (user.premium === 2) {
        silverVip.push(user);
      } else if (user.premium === 3) {
        bronzeVip.push(user);
      }
    });

    return {
      goldVip,
      silverVip,
      bronzeVip,
    };
  };

  return (
    <section className="members">
      <div className="container">
        <h2 className="featurette-heading text-center text-white">Спонсоры</h2>
        <hr className="border-2 mx-auto border-primary w-25" />
        <div className="row mt-5">
          <div className="col-md-8 mx-auto text-center">
            
            {sponsors.goldVip && sponsors.goldVip.length > 0 && (
              <div>
                <h3 className="text-white"><img src="https://hub.mileageriot.com/assets/images/vip_1.png" width={40} alt="" /> Gold VIP</h3>
                <div className="row d-flex justify-content-center align-center">
                    {sponsors.goldVip.map((user) => (
                    <div key={user.id} className="text-center mt-2 col-md-3">
                        <img
                            className="rounded-circle"
                            style={{ width: '10rem', height: '10rem' }}
                            src={user.avatar}
                            alt=""
                        />
                        <h3 className="text-white">{user.name}</h3>
                    </div>
                    ))}
                </div>
              </div>
            )}
            <hr className="border-2 mx-auto border-primary w-75 m-3" />
            {sponsors.silverVip && sponsors.silverVip.length > 0 && (
              <div>
                <h3 className="text-white"><img src="https://hub.mileageriot.com/assets/images/vip_2.png" width={40} alt="" /> Silver VIP</h3>
                <div className="row d-flex justify-content-center align-center">
                    {sponsors.silverVip.map((user) => (
                    <div key={user.id} className="text-center mt-2 col-md-3">
                        <img
                            className="rounded-circle"
                            style={{ width: '10rem', height: '10rem' }}
                            src={user.avatar}
                            alt=""
                        />
                        <h3 className="text-white">{user.name}</h3>
                    </div>
                    ))}
                </div>
              </div>
            )}
            <hr className="border-2 mx-auto border-primary w-75 m-3" />
            {sponsors.bronzeVip && sponsors.bronzeVip.length > 0 && (
              <div>
                <h3 className="text-white"><img src="https://hub.mileageriot.com/assets/images/vip_3.png" width={40} alt="" /> Bronze VIP</h3>
                <div className="row d-flex justify-content-center align-center">
                    {sponsors.bronzeVip.map((user) => (
                    <div key={user.id} className="text-center mt-2 col-md-3">
                        <img
                            className="rounded-circle"
                            style={{ width: '10rem', height: '10rem' }}
                            src={user.avatar}
                            alt=""
                        />
                        <h3 className="text-white">{user.name}</h3>
                    </div>
                    ))}
                </div>
              </div>
            )}
            

            
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sponsors;
