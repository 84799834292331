import React from 'react';

function NotFoundPage() {
  return (
    <div className='container text-center'>
      <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh'}}>
        <h1 className='text-white'>404 - Page Not Found</h1>
      </div>
    </div>
  );
}

export default NotFoundPage;
