import React from 'react';
import { FaInstagram, FaTruck, FaTwitch, FaVk, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div>
        <hr class="border-2 border-primary" />
        <footer>
        <div class="container">
            <div class="row">
            <div class="col-md-8">
                <p class="text-white">© 2023, Все права защищены. <Link to="/privacy" class="text-decoration-none text-white">Terms of Service Privacy Policy</Link></p>
            </div>
            <div class="col-md-4 text-center">
                <div class="row">
                    <div class="col-md-12">
                        <Link to="https://truckersmp.com/vtc/48336-mileageriot" target="_blank" title="TruckersMP" className="btn btn-icon btn-icon-tmp">
                            <FaTruck/>
                        </Link>
                        <Link to="https://vk.com/mileageriot" target="_blank" title="Vk" className="btn btn-icon btn-icon-vk">
                            <FaVk/>
                        </Link>
                        <Link to="https://www.youtube.com/@mileageriot_official" target="_blank" title="YouTube" className="btn btn-icon btn-icon-youtube">
                            <FaYoutube/>
                        </Link>
                        <Link to="https://twitch.tv/mileageriot_official" target="_blank" title="Twitch" className="btn btn-icon btn-icon-twitch">
                            <FaTwitch/>
                        </Link>
                        <Link to="https://www.instagram.com/mileageriot_official/" target="_blank" title="Instagram" className="btn btn-icon btn-icon-instagram">
                            <FaInstagram/>
                        </Link>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </footer>
    </div>
  );
};

export default Footer;
