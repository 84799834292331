import React, { createContext, useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, NavLink } from 'react-router-dom';
import Home from './pages/Home';

import Logo from './assets/img/logo.svg';
import Events from './pages/Events';
import Privacy from './pages/Privacy';
import Members from './pages/Members';
import ConvoyItem from './pages/ConvoyItem';
import Loader from './components/Loader';
import ScrollToTop from './components/ScrollToTop';
import { FaUser } from 'react-icons/fa';
import Footer from './components/Footer';
import News from './pages/News';
import NewsItem from './pages/NewsItem';
import NotFoundPage from './pages/NotFoundPage'
import Sponsors from './pages/Sponsors';

// Create a context for the loading state
const LoadingContext = createContext();

function LoadingProvider({ children }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1100); // Change this value to control the duration of the loader

    return () => clearTimeout(timer);
  }, []);

  return (
    <LoadingContext.Provider value={loading}>
      {children}
    </LoadingContext.Provider>
  );
}

// Create a custom hook to use the loading context
function useLoading() {
  const context = useContext(LoadingContext);

  if (context === undefined) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }

  return context;
}

function App() {
  return (
    <LoadingProvider>
      <LoadingWrapper />
     
    </LoadingProvider>
  );
}

function LoadingWrapper() {
  const loading = useLoading();

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <React.StrictMode>
          <Router>
          <div>   
            <header>
                <nav className="navbar navbar-expand-lg">
                    <div className="container">
                      <Link className="navbar-brand" to="/">
                        <img src={Logo} alt="" />
                      </Link> 
                      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                      </button> 
                      <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mx-auto menu">
                          <li className="nav-item">
                              <NavLink className="nav-link" exact to="/" activeClassName="active">Главная</NavLink>
                          </li>
                          <li className="nav-item">
                              <NavLink className="nav-link" to="/news" activeClassName="active">Новости</NavLink>
                          </li>
                          <li className="nav-item">
                              <NavLink className="nav-link" to="/staff" activeClassName="active">Команда</NavLink>
                          </li>
                          <li className="nav-item">
                              <NavLink className="nav-link" to="/events" activeClassName="active">Конвои</NavLink>
                          </li>
                          <li className="nav-item">
                              <NavLink className="nav-link" to="/sponsors" activeClassName="active">Спонсоры</NavLink>
                          </li>
                          <li className="nav-item d-md-none d-block">
                              <NavLink className="nav-link" to="https://hub.mileageriot.com/dashboard" activeClassName="active">Войти</NavLink>
                          </li>
                        </ul>
                        <div className="d-none d-md-block">
                          <Link to="https://hub.mileageriot.com/dashboard" className="btn btn-join" type="submit"><FaUser className='me-1'/> Войти</Link>
                        </div>
                      </div>
                    </div>
                  </nav>
            </header>
    

            <ScrollToTop />
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/events' element={<Events />} />
              <Route path='/news' element={<News />} />
              <Route path="/news/:slug" element={<NewsItem/>} />
              <Route path="/events/:id" element={<ConvoyItem/>} />
              <Route path='/privacy' element={<Privacy />} />
              <Route path='/staff' element={<Members />} />
              <Route path='/sponsors' element={<Sponsors />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
            <Footer />
          </div>
        </Router>
        </React.StrictMode>
      )}
      </div>
  );
}
export default App;
