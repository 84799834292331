import React, { useState, useEffect } from 'react';
import { FaCalendarAlt, FaClock, FaMap, FaMapMarked, FaMapMarkedAlt, FaRegClock, FaRoad, FaServer } from 'react-icons/fa';


import { Link } from 'react-router-dom';

const Events = () => {
  const [eventsData, setEventsEvents] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat('ru', { year: 'numeric', month: '2-digit', day: '2-digit' });
    return formatter.format(date);
  };


  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const cachedData = localStorage.getItem('eventsData');
        const cachedTimestamp = localStorage.getItem('eventsDataTimestamp');
  
        const isCacheValid = cachedTimestamp && (new Date().getTime() - new Date(parseInt(cachedTimestamp)).getTime()) < 1 * 3600 * 1000; // 3 hours in milliseconds
  
        if (cachedData && isCacheValid) {
          setEventsEvents(JSON.parse(cachedData));
        } else {
          const response = await fetch('https://hub.mileageriot.com/api/events');
          const data = await response.json();
          const sortedEvents = data;
          setEventsEvents(sortedEvents);
          localStorage.setItem('eventsData', JSON.stringify(sortedEvents));
          localStorage.setItem('eventsDataTimestamp', new Date().getTime().toString());
        }
      } catch (error) {
        console.error('Error fetching Events data:', error);
      }
    };
  
    fetchEvents();
  }, []);
  
  

  return (
    <section className="events">
        <div className="container">
            <h2 className="featurette-heading  text-center text-white">Конвои</h2>
            <hr className="border-2 mx-auto border-primary w-25" />
            <div className='row mt-5'>
                {Array.isArray(eventsData) ? (
                    <>
                    {eventsData.map((eventsItem, index) => (
                        <div key={index} className="col-md-4 mb-4 col-12">
                            <div className="card border-primary bg-transparent" style={{ minHeight: '30rem'}}>
                                <Link to={`/events/${eventsItem.id}`}>
                                    <img className="img-fluid rounded image" style={{ height: '8rem', width: '100%'}} src={eventsItem.banner ?? 'https://hub.mileageriot.com/assets/images/banner/banner.jpg'} alt="" />
                                    <div className="p-3">
                                      <h5 className="text-white" style={{ minHeight: '3rem'}}>{eventsItem.title}</h5>
                                        <hr className='border-2 border-primary w-100'/>
                                        <h6 className="text-white"><FaCalendarAlt className='text-primary' style={{ marginRight: '5px' }}/>Дата: <span className='text-primary'>{formatDate(eventsItem.date)}</span></h6>
                                        <h6 className="text-white"><FaClock className='text-primary' style={{ marginRight: '5px' }}/>Время парковки: <span className='text-primary'>{eventsItem.meetup_time} UTC</span></h6>
                                        <h6 className="text-white"><FaRegClock className='text-primary' style={{ marginRight: '5px' }}/>Время выезда: <span className='text-primary'>{eventsItem.departure_time} UTC</span></h6>
                                        <h6 className="text-white"><FaMapMarkedAlt className='text-primary' style={{ marginRight: '5px' }}/>Место парковки: <span className='text-primary'>{eventsItem.start_location}</span></h6>
                                        <h6 className="text-white"><FaMapMarked className='text-primary' style={{ marginRight: '5px' }}/>Место прибытия: <span className='text-primary'>{eventsItem.end_location}</span></h6>
                                        <h6 className="text-white"><FaMap className='text-primary' style={{ marginRight: '5px' }}/>DLC: <span className='text-primary'>{eventsItem.dlc}</span></h6>
                                        <h6 className="text-white"><FaServer className='text-primary' style={{ marginRight: '5px' }}/>Сервер: <span className='text-primary'>{eventsItem.server}</span></h6>
                                        <h6 className="text-white"><FaRoad className='text-primary' style={{ marginRight: '5px' }}/>Дистанция: <span className='text-primary'>{eventsItem.distance} KM</span></h6>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    
                    ))}
                    </>
                ) : (
                    <h3 className='text-white text-center'>Что то пошло не так...</h3>
                )}
            </div>
        </div>
    </section>
  );
};

export default Events;
