import React from 'react';
import { FaSteam } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Apply = () => {
  return (
    <section class="apply">
      <div class="container text-center mt-5">
        <h2 class="featurette-heading text-white">Присоединяйтесь к нам сегодня</h2>
        <hr class="border-2 mx-auto border-primary w-50"/>
        <div class="text-center">
          <div class="h5 text-white">
            Одним нажатием кнопки вы сможете превратить свой опыт игры в автосимуляторы в нечто стоящее. <br/> Нажмите кнопку ниже, чтобы присоединиться к лучшей русскоязычной компании!
          </div>
          <Link to="https://apply.mileageriot.com" className="btn btn-apply mt-2 animate__slower animate__animated animate__pulse animate__infinite">
            <FaSteam className='me-1'/>
            Вступить
          </Link>    
        </div>
      </div>
    </section>
  );
};

export default Apply;
