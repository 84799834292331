import React from 'react';

import { Link } from 'react-router-dom';
import Tmpru from '../assets/img/tmpru.png';
import Trucky from '../assets/img/truckway.png';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const Partners = () => {



    return (
        <section className="partners">
            <div className="container text-center">
                <h2 className="featurette-heading text-white">Мы сотрудничаем</h2>
                <hr className="border-2 mx-auto border-primary w-50" />
                <div className="row mt-4 mx-auto">
                    <div className="col-md-4 mx-auto">
                        <Link to="https://www.youtube.com/@truckwayets2" target="_blank" rel="noopener noreferrer">
                            <img src={Trucky} className="img-fluid" style={{ width: '15rem' }} alt="" />
                        </Link>
                    </div>
                    <div className="col-md-4 mx-auto">
                        <Link to="https://vk.com/tmpru" target="_blank" rel="noopener noreferrer">
                            <img src={Tmpru} className="img-fluid" style={{ width: '15rem' }} alt="" />
                        </Link>
                    </div>
                </div>
            </div>
            <div className="container text-center mt-5">
                <h2 className="featurette-heading text-white">Наши партнёры</h2>
                <hr className="border-2 mx-auto border-primary w-75" />
            </div>
            <div className="container-fluid mt-4">
                <OwlCarousel
                    className='owl-theme'
                    loop
                    margin={10}
                    dots={false}
                    autoplay={true}
                    autoplaySpeed={500}
                    responsive={{
                        0: {
                            items: 1
                        },
                        600: {
                            items: 3
                        },
                        1000: {
                            items: 5
                        }
                    }}
                >
                    <div className="item">
                        <Link to="https://arabtransportvtc.wordpress.com" target="_blank" rel="noopener noreferrer">
                            <img src="https://old.mileageriot.com/assets/admin/images/partners/Arab%20Transport%20VTC1671624989.webp" className="img-fluid rounded-circle mx-auto" style={{ width: '15rem' }} alt="" />
                        </Link>
                    </div>
                    <div className="item">
                        <Link to="https://ykn-u.ru" target="_blank" rel="noopener noreferrer">
                            <img src="https://old.mileageriot.com/assets/admin/images/partners/Yukon%20Union1661434626.webp" className="img-fluid rounded-circle mx-auto" style={{ width: '15rem' }} alt="" />
                        </Link>
                    </div>
                    <div className="item">
                        <Link to="https://rotransexpress.ro" target="_blank" rel="noopener noreferrer">
                            <img src="https://old.mileageriot.com/assets/admin/images/partners/[RO]%20Trans%20Express1661434725.webp" className="img-fluid rounded-circle mx-auto" style={{ width: '15rem' }} alt="" />
                        </Link>
                    </div>
                    <div className="item">
                        <Link to="https://consecgroup.org" target="_blank" rel="noopener noreferrer">
                            <img src="https://old.mileageriot.com/assets/admin/images/partners/Convoy%20Security%20Group1696771157.webp" className="img-fluid rounded-circle mx-auto" style={{ width: '15rem' }} alt="" />
                        </Link>
                    </div>

                </OwlCarousel>
            </div>
        </section>
    );
};

export default Partners;
